<template>
  <page-header-wrapper :title="false">
    <a-spin :spinning="loading">
      <a-card class="card" title="该网格员的管理区域" :bordered="false">
        <a-form :form="form" class="form" v-if="isShowForm">
          <a-form-item
            label="用户名"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            {{ userInfo.userName }}
          </a-form-item>
          <a-form-item
            label="真实姓名"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            {{ userInfo.realName }}
          </a-form-item>
          <a-form-item
            label="手机号"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            {{ userInfo.mobile }}
          </a-form-item>
          <a-form-item
            label="角色"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-select v-model="userParam.roleId" placeholder="请选择角色" style="width: 350px;" @change="changeRole">
              <a-select-option v-for="item in userRoleList" :key="item.roleId" :value="item.roleId">
                {{ item.roleName }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <a-form-item
            label="区域范围"
            :labelCol="labelCol"
            :wrapperCol="wrapperCol"
          >
            <a-tree-select
              :tree-default-expand-all="true"
              v-model="selectvalue"
              style="width: 100%;"
              :listHeight="156"
              :tree-data="gridCommunityList"
              tree-checkable
              allow-clear
              :show-checked-strategy="SHOW_PARENT"
              placeholder="请选择区域范围"
              :showSearch="true"
              :treeLine="true"
              :virtual="true"
              :max-tag-count="10000"
              placement="bottomRight"
              :dropdown-style="{ maxHeight: '300px', overflow: 'auto' }"
            />
          </a-form-item>
          <a-form-item
            :wrapperCol="{ span: 24 }"
            style="text-align: center"
          >
            <a-button
              type="primary"
              @click="gotoBack"
              style="margin-right: 10px;"
            >
              返回
            </a-button>
            <a-button type="primary" :disabled="loading" @click="saveRegionPower">设置</a-button>
          </a-form-item>
        </a-form>
      </a-card>
    </a-spin>
  </page-header-wrapper>
</template>

<script>
import STree from '@/components/Tree/Tree'
import { gridHouseList, gridRangeList } from '@/api/gridhouse'
import { TreeSelect } from 'ant-design-vue'
import { saveUserRegionPower, userData, userRoleList } from '@/api/user'
const SHOW_PARENT = TreeSelect.SHOW_PARENT
export default {
  name: 'PowerInfo',
  components: {
    STree
  },
  data () {
    return {
      form: this.$form.createForm(this, { name: 'addpower' }),
      labelCol: {
        lg: { span: 7 },
        sm: { span: 7 }
      },
      wrapperCol: {
        lg: { span: 10 },
        sm: { span: 17 }
      },
      loading: false,
      memberLoading: false,
      roleParam: {
        roleId: 0
      },
      userParam: {
        userId: 0,
        roleId: 0,
        isGridType: 1
      },
      routeList: [],
      defaultExpandAll: true,
      gridCommunityList: [],
      selectedKeys: [],
      selectvalue: [],
      SHOW_PARENT,
      defaultKey: 1,
      userRoleList: [],
      checkedKeys: {
        checked: [],
        halfChecked: []
      },
      roleUserRegion: [],
      userInfo: {},
      isShowForm: true
    }
  },
  created () {
    this.isShowForm = true
    this.gridRangeListApi()
    if (this.$route.query.userId !== 0 && this.$route.query.userId !== undefined && this.$route.query.userId !== '') {
      this.userParam.userId = parseInt(this.$route.query.userId)
    }

    if (this.userParam.userId > 0) {
      this.userRoleListApi()
      this.userDataApi()
    }
  },
  watch: {
    $route (val) {
      if (val.name === 'UseRangePower') {
        this.isShowForm = false
        this.$nextTick(function () {
          this.isShowForm = true
        })
        this.gridRangeListApi()
        if (this.$route.query.userId !== 0 && this.$route.query.userId !== undefined && this.$route.query.userId !== '') {
          this.userParam.userId = parseInt(this.$route.query.userId)
        }

        if (this.userParam.userId > 0) {
          this.userRoleListApi()
          this.userDataApi()
        }
      }
    }
  },
  methods: {
    gotoBack () {
      this.$router.push({ path: '/syueuser/user-list' })
    },
    onCheck (checkedKeys, info) {
      const _this = this
      _this.checkedKeys.checked = checkedKeys
      _this.checkedKeys.halfChecked = info.halfCheckedKeys
      console.log('onCheck', checkedKeys, info)
    },
    userDataApi () {
      const _this = this
      userData(_this.userParam).then((res) => {
        if (res.errorCode === 0) {
          _this.userInfo = res.result
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    userRoleListApi () {
      const _this = this
      userRoleList(this.userParam).then((res) => {
        if (res.errorCode === 0) {
          _this.userRoleList = res.result.data
          _this.roleUserRegion = res.result.roleUserRegion
          if (_this.userRoleList.length > 0) {
            _this.userParam.roleId = _this.userRoleList[0].roleId
            _this.selectvalue = _this.roleUserRegion[_this.userParam.roleId]
          }
          console.log(_this.userRoleList)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    gridRangeListApi () {
      const _this = this
      _this.loading = true
      gridRangeList().then((res) => {
        if (res.errorCode === 0) {
          _this.gridCommunityList = res.result
          console.log('gridCommunityList', res)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.loading = false
      }).catch((err) => {
        console.log(err)
        _this.loading = false
      })
    },
    gridHouseListApi () {
      const _this = this
      gridHouseList({ unitId: '' }).then((res) => {
        if (res.errorCode === 0) {
          console.log('gridHouseList', res)
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    saveRegionPower () {
      const _this = this
      _this.loading = true
      saveUserRegionPower({ regionPower: _this.selectvalue, roleId: _this.userParam.roleId, userId: _this.userParam.userId }).then((res) => {
        if (res.errorCode === 0) {
          _this.$message.success(res.errorMsg)
          console.log('gridHouseList', res)
        } else {
          _this.$message.error(res.errorMsg)
        }
        _this.loading = false
      }).catch((err) => {
        console.log(err)
        _this.loading = false
      })
    },
    changeTab (v) {
      this.defaultKey = v
    },
    changeRole (v) {
      console.log(v)
      this.selectvalue = this.roleUserRegion[v]
    }
  }
}
</script>

<style lang="less" scoped>
.card{
  margin-bottom: 24px;
}
.popover-wrapper {
  /deep/ .antd-pro-pages-forms-style-errorPopover .ant-popover-inner-content {
    min-width: 256px;
    max-height: 290px;
    padding: 0;
    overflow: auto;
  }
}
.antd-pro-pages-forms-style-errorIcon {
  user-select: none;
  margin-right: 24px;
  color: #f5222d;
  cursor: pointer;
  i {
    margin-right: 4px;
  }
}
.antd-pro-pages-forms-style-errorListItem {
  padding: 8px 16px;
  list-style: none;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  transition: all .3s;

  &:hover {
    background: #e6f7ff;
  }
  .antd-pro-pages-forms-style-errorIcon {
    float: left;
    margin-top: 4px;
    margin-right: 12px;
    padding-bottom: 22px;
    color: #f5222d;
  }
  .antd-pro-pages-forms-style-errorField {
    margin-top: 2px;
    color: rgba(0,0,0,.45);
    font-size: 12px;
  }
}
</style>
